import { useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useDispatch } from "react-redux";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/mint-img.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import {
  useNFTPrice,
  useTotalNFTMinted,
  useUserNFTBalance,
} from "state/nft/hooks";
import { useGrapeContract, useVintnerContract } from "hooks/useContract";
import { setLoading } from "state/application/actions";
import { makeSignature } from "utils/makeSignature";
import { useWeb3 } from "state/web3";
import { wait } from "@testing-library/user-event/dist/utils";
import { VINTNER_ADDRESS } from "config/address";
const MintNowModal = () => {
  const dispatch = useDispatch();
  const { account, chainId } = useWeb3();
  const grapeContract = useGrapeContract();
  const [count, setCount] = useState(1);
  const { mintType, mintModalHandle } = useModal();
  const { NFTAVAXPrice, NFTGrapePrice } = useNFTPrice();
  const { vintnerTokenBalance } = useTotalNFTMinted();
  const { userWineryNFTBalance, userWhitelistCount, userVintnerNFTBalance } =
    useUserNFTBalance();
  const vintnerTokenContract = useVintnerContract();

  const price =
    mintType === 0 ? 0 : mintType === 1 ? NFTAVAXPrice : NFTGrapePrice;
  const maxCount = mintType === 0 ? userWineryNFTBalance : 20;

  const mintNFT = async () => {
    dispatch(
      setLoading({
        isLoading: true,
      })
    );
    if (account && count)
      if (mintType === 0 && userWineryNFTBalance) {
        // Mint for Whitelist
        const coupon = makeSignature(account, userWineryNFTBalance);
        try {
          const tx = await vintnerTokenContract.mintWhitelist(
            count,
            userWineryNFTBalance,
            coupon
          );
          const receipt = await tx.wait();
          if (receipt.status) {
            window.location.reload();
          }
        } catch (_) {}
      } else if (mintType === 1) {
        // Mint AVAX
        try {
          const tx = await vintnerTokenContract.mintVintnerWithAVAX(count, {
            value: ethers.utils.parseEther((price * count).toString()),
            // value: BigNumber.from(NFTPrice * count * Math.pow(10, 18)),
          });
          const receipt = await tx.wait();
          if (receipt.status) {
            window.location.reload();
          }
        } catch (_) {}
      } else if (mintType === 2 && chainId && grapeContract) {
        // Mint AVAX
        try {
          const isApproved = await grapeContract.allowance(
            account,
            VINTNER_ADDRESS[chainId]
          );
          if (ethers.utils.formatEther(isApproved) <= price * count) {
            const APPROVE_AMOUNT = ethers.constants.MaxUint256;
            const tx = await grapeContract.approve(
              VINTNER_ADDRESS[chainId],
              APPROVE_AMOUNT
            );
            await tx.wait();
          }
          const tx = await vintnerTokenContract.mintVintnerWithGrape(count);
          const receipt = await tx.wait();
          if (receipt.status) {
            window.location.reload();
          }
        } catch (_) {}
      }

    dispatch(
      setLoading({
        isLoading: false,
      })
    );
  };

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Mint a Vintner NFT!</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {vintnerTokenBalance}/<span>10,000</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5>
                    <h5>
                      {price} {mintType === 2 ? "Grape" : "AVAX"}
                    </h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button
                        onClick={() =>
                          count > 1 ? setCount(count - 1) : count
                        }
                      >
                        -
                      </button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          count <
                          (mintType
                            ? userWineryNFTBalance - userWhitelistCount
                            : maxCount)
                            ? setCount(count + 1)
                            : count
                        }
                      >
                        +
                      </button>
                    </div>

                    <span
                      style={{
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "800",
                      }}
                      onClick={() => setCount(maxCount)}
                    >
                      {" "}
                      MAX{" "}
                    </span>
                  </li>
                  <li style={{ justifyContent: "center" }}>
                    <h5>
                      <span>{price * count}</span>{" "}
                      {mintType === 2 ? "Grape" : "AVAX"}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="modal_mint_btn">
                <Button lg variant="mint" onClick={() => mintNFT()}>
                  Mint Now
                </Button>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
