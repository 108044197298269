import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { store } from "store";
import { Web3Provider } from "state/web3";
import NFTUpdater from "state/nft/updater";
import UserUpdater from "state/user/updater";
import ContextProvider from "./utils/ContextProvider";
import App from "./app/App";

// const client = new ApolloClient({
//   uri: "https://api.thegraph.com/subgraphs/name/letteldream/winerynft",
//   cache: new InMemoryCache(),
// });

// const client = new ApolloClient({
//   uri: "https://api.thegraph.com/subgraphs/name/letteldream/winenftgame",
//   cache: new InMemoryCache(),
// });

const root = document.getElementById("root");
render(
  
  // <ApolloProvider client={client}>
    <Provider store={store}>
      <Web3Provider>
        <NFTUpdater />
        <UserUpdater />
        <ContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContextProvider>
      </Web3Provider>
    </Provider>,
  // </ApolloProvider>,
  root
);
