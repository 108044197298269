import { createReducer } from "@reduxjs/toolkit";
import { setTokenBalance, setLoading } from "./actions";

export interface TokenState {
  isLoading: boolean;
  tokenBalance: number;
  grapeBalance: number;
}

const initialState: TokenState = {
  isLoading: false,
  tokenBalance: 0,
  grapeBalance: 0,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setLoading, (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    })
    .addCase(setTokenBalance, (state, action) => {
      const { tokenBalance, grapeBalance } = action.payload;

      state.tokenBalance = tokenBalance;
      state.grapeBalance = grapeBalance;
    })
);
