import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setNFTMintCount, setNFTPrice, setUserNFTBalance } from "./actions";
import { setLoading } from "../application/actions";
import { useVintnerContract, useWineryContract } from "hooks/useContract";
import { useWeb3 } from "state/web3";
import { SupportedChainId } from "config/address";

export default function Updater(): null {
  const dispatch = useDispatch();

  const { account, chainId } = useWeb3();
  const vintnerTokenContract = useVintnerContract();
  // const wineryTokenContract = useWineryContract();

  useEffect(() => {
    if (
      account &&
      // wineryTokenContract &&
      vintnerTokenContract &&
      (chainId === SupportedChainId.MAINNET ||
        chainId === SupportedChainId.TESTNET)
    ) {
      // Get total supply of Vintner and user balance of Winery and Vintner
      const getBalance = async () => {
        dispatch(
          setLoading({
            isLoading: true,
          })
        );

        // Get Promotional NFT count
        const vintnersMintedPromotional =
          await vintnerTokenContract.vintnersMintedPromotional();

        // Get public sale NFT count
        const vintnerPublicMinted =
          await vintnerTokenContract.vintnerPublicMinted();

        // Get Whitelist NFT count
        const vintnersMintedWhitelist =
          await vintnerTokenContract.vintnersMintedWhitelist();

        if (
          vintnersMintedPromotional !== "" &&
          vintnerPublicMinted !== "" &&
          vintnersMintedWhitelist !== ""
        )
          dispatch(
            setNFTMintCount({
              vintnerTokenBalance:
                Number(vintnersMintedPromotional) +
                Number(vintnerPublicMinted) +
                Number(vintnersMintedWhitelist),
              vintnerWhitelistBalance: +vintnersMintedWhitelist,
            })
          );

        const NFTAVAXPrice = await vintnerTokenContract.VINTNER_PRICE_AVAX();
        const NFTGrapePrice = await vintnerTokenContract.VINTNER_PRICE_GRAPE();

        dispatch(
          setNFTPrice({
            NFTAVAXPrice: Number(NFTAVAXPrice) / Math.pow(10, 18),
            NFTGrapePrice: Number(NFTGrapePrice) / Math.pow(10, 18),
          })
        );

        // Get User info

        // Get user winery nft balance

        const wineryAddressList = require("config/WineryNFTlist.json");
        let users = wineryAddressList?.data.users;
        const userWineryBalance =
          users.filter(
            (item: any) => item.id.toLowerCase() === account.toLowerCase()
          )[0]?.WineryTokens.length || 0;

        // Get user whitelist claimed count in Vintner contract
        const userWhitelistClaimed =
          await vintnerTokenContract.whitelistClaimed(account);

        // Get user vintner NFT balance
        const vintner = await vintnerTokenContract.balanceOf(account);
        dispatch(
          setUserNFTBalance({
            userWineryNFTBalance: userWineryBalance,
            userWhitelistCount: Number(userWhitelistClaimed),
            userVintnerNFTBalance: vintner.toNumber(),
          })
        );

        dispatch(
          setLoading({
            isLoading: false,
          })
        );
      };
      getBalance();
    }
  }, [account, chainId, dispatch, vintnerTokenContract]);

  return null;
}
