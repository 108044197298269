import { setNFTMintCount, setNFTPrice, setUserNFTBalance } from "./actions";
import { createReducer } from "@reduxjs/toolkit";

export interface ApplicationState {
  vintnerTokenBalance: number;
  vintnerWhitelistBalance: number;
  userWineryNFTBalance: number;
  userWhitelistCount: number;
  userVintnerNFTBalance: number;
  NFTAVAXPrice: number;
  NFTGrapePrice: number;
}

const initialState: ApplicationState = {
  vintnerTokenBalance: 0,
  vintnerWhitelistBalance: 0,
  userWineryNFTBalance: 0,
  userWhitelistCount: 0,
  userVintnerNFTBalance: 0,
  NFTAVAXPrice: 0,
  NFTGrapePrice: 0,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setNFTMintCount, (state, action) => {
      const { vintnerTokenBalance, vintnerWhitelistBalance } = action.payload;
      state.vintnerTokenBalance = vintnerTokenBalance;
      state.vintnerWhitelistBalance = vintnerWhitelistBalance;
    })
    .addCase(setNFTPrice, (state, action) => {
      const { NFTAVAXPrice, NFTGrapePrice } = action.payload;
      state.NFTAVAXPrice = NFTAVAXPrice;
      state.NFTGrapePrice = NFTGrapePrice;
    })
    .addCase(setUserNFTBalance, (state, action) => {
      const {
        userWineryNFTBalance,
        userWhitelistCount,
        userVintnerNFTBalance,
      } = action.payload;
      state.userWineryNFTBalance = userWineryNFTBalance;
      state.userWhitelistCount = userWhitelistCount;
      state.userVintnerNFTBalance = userVintnerNFTBalance;
    })
);
