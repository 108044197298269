export enum SupportedChainId {
  MAINNET = 43114,
  TESTNET = 43113,
  HEX_MAINNET = "0xa86a",
  HEX_TESTNET = "0xa869",
}

type AddressMap = { [chainId: number]: string };

export const GRAPE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0x5541D83EFaD1f281571B343977648B75d95cdAC2",
  [SupportedChainId.TESTNET]: "0xD1328c498A7B2Befe61e33B3f4687935CA1f4b4c",
};

export const VINTNER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0xe26168f45030E1Eb7477Fa5F9A4a28d93c0658B4",
  [SupportedChainId.TESTNET]: "0x1DC322CB392F608139Cd52088Aa8C167f826aE17",
};

export const WINERY_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: "0xA52aa55c9eacd0e447f4781570AF8E4854caC954",
  [SupportedChainId.TESTNET]: "",
};
