import { keccak256, toBuffer, ecsign, bufferToHex } from "ethereumjs-util";
import { ethers } from "ethers";

function serializeCoupon(coupon: any) {
  return {
    r: bufferToHex(coupon.r),
    s: bufferToHex(coupon.s),
    v: coupon.v,
  };
}

export const makeSignature = (account: string, allotted: number) => {
  const privateKey =
    "429f5d8f41beb81e2d634b1667d81c8be95b946dcbe119252316e8182d6d1c49";

  const signerPvtKey = Buffer.from(privateKey, "hex");

  const hashBuffer = keccak256(
    toBuffer(
      ethers.utils.defaultAbiCoder.encode(
        ["uint256", "address"],
        [allotted, account]
      )
    )
  );

  const coupon = ecsign(hashBuffer, signerPvtKey);
  const serialize = serializeCoupon(coupon);

  return serialize;
};
