import { createAction } from "@reduxjs/toolkit";

export const setLoading = createAction<{
  isLoading: boolean;
}>("user/setLoading");

export const setTokenBalance = createAction<{
  tokenBalance: number;
  grapeBalance: number;
}>("user/setTokenBalance");

