import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { useWeb3 } from "state/web3";
import { getContract } from "utils";
import { GRAPE_ADDRESS, VINTNER_ADDRESS, WINERY_ADDRESS } from "config/address";
import WINERY_ABI from "abi/winery.json";
import VINTNER_ABI from "abi/vintner.json";
import GRAPE_ABI from "abi/grape.json";
import { getProvider } from "utils/provider";
import NETWORKS from "config/network";

export function useContract(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: { [chainId: number]: any } | any,
  withSignerIfPossible = true
): Contract | null {
  const { chainId, provider, account } = useWeb3();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    let abi: any;
    if (!Array.isArray(ABI) && Object.keys(ABI).length > 0) abi = ABI[chainId];
    else abi = ABI;
    if (!abi) return null;
    try {
      return getContract(
        address,
        abi,
        provider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]);
}

export function useGrapeContract() {
  return useContract(GRAPE_ADDRESS, GRAPE_ABI);
}

export function useVintnerContract() {
  return useContract(VINTNER_ADDRESS, VINTNER_ABI);
}

export function useWineryContract() {
  return useMemo(() => {
    const address = WINERY_ADDRESS[43114];
    const abi = WINERY_ABI;
    const provider = getProvider(43114);
    try {
      return getContract(address, abi, provider);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, []);
}
