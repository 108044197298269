import { useAppDispatch } from "../hooks";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "state/hooks";
import { AppState } from "store";

export function useTotalNFTMinted() {
  const vintnerTokenBalance = useSelector(
    (state: AppState) => state.nft.vintnerTokenBalance
  );
  const vintnerWhitelistBalance = useSelector(
    (state: AppState) => state.nft.vintnerWhitelistBalance
  );
  return { vintnerTokenBalance, vintnerWhitelistBalance };
}

export function useNFTPrice() {
  const NFTAVAXPrice = useSelector((state: AppState) => state.nft.NFTAVAXPrice);
  const NFTGrapePrice = useSelector(
    (state: AppState) => state.nft.NFTGrapePrice
  );
  return { NFTAVAXPrice, NFTGrapePrice };
}

export function useUserNFTBalance() {
  const userWineryNFTBalance = useSelector(
    (state: AppState) => state.nft.userWineryNFTBalance
  );
  const userWhitelistCount = useSelector(
    (state: AppState) => state.nft.userWhitelistCount
  );
  const userVintnerNFTBalance = useSelector(
    (state: AppState) => state.nft.userVintnerNFTBalance
  );
  return { userWineryNFTBalance, userWhitelistCount, userVintnerNFTBalance };
}
