import { createAction } from "@reduxjs/toolkit";

export const setNFTMintCount = createAction<{
  vintnerTokenBalance: number;
  vintnerWhitelistBalance: number;
}>("nft/setNFTMintCount");

export const setNFTPrice = createAction<{
  NFTAVAXPrice: number;
  NFTGrapePrice: number;
}>("nft/setNFTPrice");

export const setUserNFTBalance = createAction<{
  userWineryNFTBalance: number;
  userWhitelistCount: number;
  userVintnerNFTBalance: number;
}>("nft/setUserNFTBalance");
