import { useSelector } from "react-redux";
import { AppState } from "store";

export function useIsLoading() {
  return useSelector((state: AppState) => state.user.isLoading);
}

export function useTokenBalance() {
  const tokenBalance = useSelector(
    (state: AppState) => state.user.tokenBalance
  );
  const grapeBalance = useSelector(
    (state: AppState) => state.user.grapeBalance
  );
  return { tokenBalance, grapeBalance };
}
