import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/nft/gif.gif";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useNFTPrice, useTotalNFTMinted } from "state/nft/hooks";
import { useUserNFTBalance } from "state/nft/hooks";
import { useTokenBalance } from "state/user/hooks";
import { trim } from "utils/trim";

const Banner = () => {
  const { mintModalHandle, setMintType } = useModal();
  const { vintnerTokenBalance } = useTotalNFTMinted();
  const { tokenBalance, grapeBalance } = useTokenBalance();
  const { NFTPrice } = useNFTPrice();
  const { vintnerWhitelistBalance } = useTotalNFTMinted();
  const { userWineryNFTBalance, userWhitelistCount, userVintnerNFTBalance } =
    useUserNFTBalance();

  const mintFree = () => {
    mintModalHandle();
    setMintType(0);
  };

  const mintPublic = (token) => {
    mintModalHandle();
    if (token === "AVAX") setMintType(1);
    else setMintType(2);
  };

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>Vintner NFT collection</h2>
              <h4 style={{ color: "white" }}>
                Your AVAX: {trim(tokenBalance, 2)}
              </h4>
              <h4 style={{ color: "white" }}>
                Your Grape: {trim(grapeBalance, 2)}
              </h4>

              <h3>
                <span className="count">
                  <Counter end={vintnerTokenBalance} duration={1} />
                </span>{" "}
                / 10,000 Minted <br />{" "}
              </h3>
              <h4 style={{ color: "white" }}>
                Your Minted Count: {userVintnerNFTBalance}
              </h4>
              {userWhitelistCount ? (
                <h4 style={{ color: "white" }}>
                  Your Whitelist Minted Count: {userWhitelistCount}
                </h4>
              ) : (
                <div />
              )}
              {userWineryNFTBalance && vintnerWhitelistBalance < 2700 ? (
                <div>
                  <div className="banner_buttons">
                    <Button lg variant="blue" onClick={mintFree}>
                      Free Mint
                    </Button>
                  </div>
                  <h4 style={{ color: "white", marginTop: "30px" }}>
                    You are a Whitelist member! <br /> You can mint
                    <span style={{ color: "cyan", fontWeight: 800 }}>
                      &nbsp; {userWineryNFTBalance - userWhitelistCount} NFTS
                      &nbsp;
                    </span>
                    for FREE!
                  </h4>
                </div>
              ) : (
                <div />
              )}
              <div className="banner_buttons">
                <Button lg variant="mint" onClick={() => mintPublic("AVAX")}>
                  Mint with Avax
                </Button>
                <Button lg variant="mint" onClick={() => mintPublic("GRAPE")}>
                  Mint with Grape
                </Button>
              </div>
              <div className="coin-info">
                <span>Max 20 NFTs per transaction</span>
                <span>Price: 50 Grape or 2.5 Avax per NFT</span>
                {/* <span>
                  MINT IS LIVE{" "}
                  <span className="highlighted">UNTIL 25 APR 04:00H</span>
                </span> */}
                {/* <span>Presale : SOLDOUT</span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
