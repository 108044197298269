import { setTokenBalance, setLoading } from "./actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";

import { useWeb3 } from "state/web3";
import { SupportedChainId } from "config/address";
import NETWORKS from "config/network";
import { useGrapeContract } from "hooks/useContract";

export default function Updater(): null {
  const dispatch = useDispatch();

  const { chainId, account } = useWeb3();

  const grapeContract = useGrapeContract();
  // Make Random Wallet for Coupon

  // const wallet = ethers.Wallet.createRandom()
  // console.log('Public Address:', wallet.address)
  // console.log('Private Key:', wallet.privateKey)

  // Get user balance of Avax
  useEffect(() => {
    if (
      window &&
      account &&
      grapeContract &&
      (chainId === SupportedChainId.MAINNET ||
        chainId === SupportedChainId.TESTNET)
    ) {
      const getBalance = async () => {
        dispatch(
          setLoading({
            isLoading: true,
          })
        );

        const provider = new ethers.providers.Web3Provider(
          window.ethereum as any
        );
        const signer = provider.getSigner();
        const balance = await signer.getBalance();
        const balanceInEth = ethers.utils.formatEther(balance);

        const grapeBalance = await grapeContract.balanceOf(account);

        if (balanceInEth !== "")
          dispatch(
            setTokenBalance({
              tokenBalance: Number(balanceInEth),
              grapeBalance: +ethers.utils.formatEther(grapeBalance),
            })
          );
        dispatch(
          setLoading({
            isLoading: false,
          })
        );
      };
      getBalance();
    } else {
      dispatch(
        setTokenBalance({
          tokenBalance: 0,
          grapeBalance: 0,
        })
      );
    }
  }, [account, chainId, dispatch, grapeContract]);

  return null;
}
