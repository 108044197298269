import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import { useIsLoading as updatingNFT } from "state/application/hooks";
import { useIsLoading as updatingUser } from "state/user/hooks";
import Loading from "components/Loading";

function App() {
  const isLoadingNFT = updatingNFT();
  const isLoadingUser = updatingUser();

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeV1 />} exact />
        <Route path="/home-two" element={<HomeV2 />} />
        <Route path="/home-three" element={<HomeV3 />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/post" element={<BlogDetails />} />
      </Routes>
      <Loading isLoading={isLoadingNFT || isLoadingUser} />
    </>
  );
}

export default App;
